import ApiDefault from "./ApiDefault";

const PATH = 'v1/deals';

export default class Deal extends ApiDefault {
  async create(data) {
    return this.post(PATH, data);
  }

  async all(data) {
    return this.get(PATH + '/table' + data);
  }
}
