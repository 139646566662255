import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dealApi } from "helpers";

const name = "deal";
const initialState = [];

const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, reducers, extraReducers});

export const dealActions = { ...slice.actions, ...extraActions };
export const dealReducer = slice.reducer;

function createReducers() {

    return {
        all
    };

    function all(state, { payload }) {

        return state;
    }
}

function createExtraActions() {

    return {
        all: all()
    };

    function all() {
        return createAsyncThunk(`${name}/all`, async () => await dealApi.all());
    }
}

function createExtraReducers() {

    return {
        ...all()
    };

    function all() {
        const { fulfilled } = extraActions.all;
        return {
          [fulfilled]: (state, action) => {
            return action.payload.result;
          },
        };
    }
}
